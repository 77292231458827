<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-15">
      <span class="a-fs-16 a-fw-b">用户反馈</span>
    </div>
    <el-card class="el-main sceneCheckContent">
      <le-search-form
        id="leSearch"
        :can-pack-up="false"
        @reset="handlerRest"
        @search="handlerSearch"
      >
        <le-input v-model="pageParam.params.mobile" label="手机号" />
      </le-search-form>
      <le-pagview ref="sceneCheckList" :page-param="pageParam" @setData="setTableData">
        <el-table
          v-sticky="{ top: 0, parent: '.el-card__body' }"
          :data="computedTableData"
          :highlight-current-row="true"
          style="width: 100%"
          @filter-change="filterFun"
        >
          <el-table-column prop="mobile" type="number" label="手机号"></el-table-column>
          <el-table-column label="反馈类型" prop="typeLabel"></el-table-column>
          <el-table-column label="反馈内容" prop="advice"></el-table-column>
          <el-table-column prop="createTime" label="创建时间"></el-table-column>
          <!-- <el-table-column prop="name" label="操作" width="75" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="getInfo(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column> -->
        </el-table>
        <template slot="footerLabel" slot-scope="scope">
          <div v-if="scope.val != -1" class="a-flex-rfsc">
            <span class="a-fs-12 a-c-normal">筛选出</span>
            <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{ scope.val }}</span>
            <span class="a-fs-12 a-c-normal a-plr-10">条记录</span>
          </div>
        </template>
      </le-pagview>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "FeedbackList",

  data() {
    return {
      tableData: [],
      typeConfig: [],
      pageParam: {
        url: this.$Config.apiUrl.getAdviceList,
        method: "post",
        params: {
          mobile: "",
        },
        freshCtrl: 1,
      },
      optionsCustomerType: [
        {
          text: "公司",
          value: 0,
        },
        {
          text: "个人",
          value: 1,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      company: (state) => state.company.company,
    }),
    computedTableData() {
      return this.tableData.map((v) => ({
        ...v,
        typeLabel: this.typeConfig.find((childV) => childV.typeId === v.feedbackType)
          ? this.typeConfig.find((childV) => childV.typeId === v.feedbackType).typeName
          : "",
      }));
    },
  },
  created() {
    this.$Axios
      ._get({
        url: this.$Config.apiUrl.getFeedbackTypeConfig,
      })
      .then((res) => {
        if (res.result.code == 0) {
          this.typeConfig = res.result.data;
        } else {
          this.$message.error(res.result.message);
        }
      });
    this.pageParam.params.currentCompanyId = this.company.id;
  },
  methods: {
    //获取设备列表
    setTableData(data) {
      this.tableData = data;
    },
    handlerRest() {
      this.pageParam.params = {
        mobile: "",
      };
      this.handlerSearch();
    },
    handlerSearch() {
      this.pageParam.freshCtrl++;
    },
    //条件筛选
    filterFun(value) {
      for (const key in value) {
        if (key == "customerType") {
          if (value[key].length == 0) {
            this.pageParam.params.customerType = "";
          } else {
            this.pageParam.params.customerType = value[key][0];
          }
        }
      }
      this.$refs["sceneCheckList"].pageNum = 1;
      this.handlerSearch();
    },
    // 查看现勘详情
    getInfo(datas) {
      this.$router.push({
        path: "/sceneCheck/sceneCheck-info",
        query: {
          id: datas.id,
          title: datas.areaName,
        },
      });
    },
    exportfile() {
      if (!this.fileUrl) {
        this.$message.warning("暂无可导出文件");
        return;
      }
      this.$confirm("是否确认导出文件？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$Utils.downloadUrlFile(this.fileUrl, "省份经营数据列表");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .sceneCheckContent {
  .el-table__column-filter-trigger {
    display: none;
  }
}
</style>
